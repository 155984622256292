<template>
	<div 
		v-if="loading"
		class="h-full flex items-center justify-center">
		
		<div class="spinner"></div>
	</div>
	<div 
		v-else-if="stats?.account && stats.departments && stats.departments.length"
		class="h-full">
		
		<div 
			v-if="mode == 'all' || mode == 'total'"
			class="">
			
			<!-- userStatsMode: {{ userStatsMode }} -->
			
			<div class="text-center mb-6">
				<h1>
					{{useDepartmentTargets ? departmentTargetUnitName : 'Totalt for valgt periode' }}
				</h1>
				
				<h3 v-if="stats.dateRange && stats.dateRange.fromTimestamp">
					<span class="">
						<span class="capitalize">{{ stats.dateRange.fromTimestamp  | moment('dddd') }}</span>
						{{ stats.dateRange.fromTimestamp  | moment('Do MMM YYYY') }}
					</span>
					<span class="mx-3 opacity-75">
						<i class="fas fa-chevron-right" />
					</span>
					<span class="">
						<span class="capitalize">{{ stats.dateRange.toTimestamp  | moment('dddd') }}</span>
						{{ stats.dateRange.toTimestamp  | moment('Do MMM YYYY') }}
					</span>
				</h3>
			</div>
			
			
			<div 
				v-if="signedInUser"
				class="bg-beige-dark w-full">
				
				<div class="flex space-x-4 mx-auto max-w-sm pt-6 pb-12">
					
					<div class="flex-1 mt-4 text-left  flex items-center">
						
						<div class="">
							<div class="opacity-75 whitespace-no-wrap">Mine resultater</div>
							
							<div v-if="stats.user?.totals">
								<div v-if="stats.user.totals.products?.sum || stats.user.totals.tasks?.qty">
									<div 
										v-if="stats.user.totals.products?.sum"
										class="flex">
										
										<div class="mr-1">Salg:</div>
										<div class="whitespace-nowrap">kr {{currencyFormat(stats.user.totals.products.sum)}}</div>
									</div>
									<div 
										v-if="stats.user.totals.tasks?.qty"
										class="flex">
										
										<div class="mr-1">Oppgaver:</div>
										<div class="whitespace-nowrap">{{ stats.user.totals.tasks?.qty }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					
					<div class="flex-none rounded-full w-32 h-32 flex overflow-hidden bg-beige-dark border-white">
						<img 
							v-if="picture"
							:src="picture"
							class="w-full h-full object-cover" 
						/>
						<i 
							v-else
							class="far fa-grin-wink w-full h-full items-center justify-center flex text-2xl bg-beige-dark" 
						/>
					</div>
					
					<div class="flex-1 mt-4 flex flex-col items-end text-right">
						<div class="flex-none space-x-4 flex items-center h-full ">
							<div 
								v-if="stats.user.totals.kudos.heart"
								class="rounded-full flex items-center justify-center space-x-1 px-2 h-8 bg-white">
								
								<i 
									class="fas w-6 text-center fa fa-heart" 
								/>
								<div>
									{{stats.user.totals.kudos.heart}}
								</div>
							</div>
							
							<div 
								v-if="stats.user.totals.kudos.thumbsUp"
								class="rounded-full flex items-center justify-center space-x-1 px-2 h-8 bg-white">
								
								<i 
									class="fas w-6 text-center fa fa-thumbs-up" 
								/>
								<div>
									{{stats.user.totals.kudos.thumbsUp}}
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
			
<!-- 			
			<div class="w-full overflow-hidden bg-yellow-500">
				<div 
					class="bg-beige-dark -mx-32 pt-4"
					style="border-bottom-right-radius: 50%; border-bottom-left-radius: 50%;">
					
					<slot></slot>
					
					<div class="text-center">
						<h1 class="">
							{{useDepartmentTargets ? departmentTargetUnitName : 'Totalt for valgt periode' }}
						</h1>
						
						<h3 
							v-if="stats.dateRange && stats.dateRange.fromTimestamp"
							class="opacity-75">
							
							<span class="">
								<span class="capitalize">{{ stats.dateRange.fromTimestamp  | moment('dddd') }}</span>
								{{ stats.dateRange.fromTimestamp  | moment('Do MMM YYYY') }}
							</span>
							<span class="mx-3 opacity-75">
								<i class="fas fa-chevron-right" />
							</span>
							<span class="">
								<span class="capitalize">{{ stats.dateRange.toTimestamp  | moment('dddd') }}</span>
								{{ stats.dateRange.toTimestamp  | moment('Do MMM YYYY') }}
							</span>
						</h3>
					</div>
					
					
					
					<div class="flex space-x-4 mx-auto max-w-sm pt-6 pb-12">
						<div class="flex-1 mt-4 text-left  flex items-center">
							
							<div class="">
								<div class="opacity-75 whitespace-no-wrap">Mine resultater</div>
								
								<div v-if="stats.user?.totals">
									<div v-if="stats.user.totals.products?.sum || stats.user.totals.tasks?.qty">
										<div 
											v-if="stats.user.totals.products?.sum"
											class="flex">
											
											<div class="mr-1">Salg:</div>
											<div class="whitespace-nowrap">kr {{currencyFormat(stats.user.totals.products.sum)}}</div>
										</div>
										<div 
											v-if="stats.user.totals.tasks?.qty"
											class="flex">
											
											<div class="mr-1">Oppgaver:</div>
											<div class="whitespace-nowrap">{{ stats.user.totals.tasks?.qty }}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						<div class="flex-none rounded-full w-32 h-32 flex overflow-hidden bg-beige-dark border-white">
							<img 
								v-if="picture"
								:src="picture"
								class="w-full h-full object-cover" 
							/>
							<i 
								v-else
								class="far fa-grin-wink w-full h-full items-center justify-center flex text-2xl bg-beige-dark" 
							/>
						</div>
						
						<div class="flex-1 mt-4 flex flex-col items-end text-right">
							<div class="flex-none space-x-4 flex items-center h-full ">
								<div 
									v-if="stats.user.totals.kudos.heart"
									class="rounded-full flex items-center justify-center space-x-1 px-2 h-8 bg-white">
									
									<i 
										class="fas w-6 text-center fa fa-heart" 
									/>
									<div>
										{{stats.user.totals.kudos.heart}}
									</div>
								</div>
								
								<div 
									v-if="stats.user.totals.kudos.thumbsUp"
									class="rounded-full flex items-center justify-center space-x-1 px-2 h-8 bg-white">
									
									<i 
										class="fas w-6 text-center fa fa-thumbs-up" 
									/>
									<div>
										{{stats.user.totals.kudos.thumbsUp}}
									</div>
								</div>
							</div>
						</div>
					</div>
					
				</div>
			</div>
		
			 -->
			
			
			
			
			
			
			
			
			
			
<!-- 			
			
			<div v-if="userStatsMode == 'app'">
				<div class="w-full overflow-hidden">
					
					<div 
						class="bg-beige-dark -mx-32 pt-4"
						style="border-bottom-right-radius: 50%; border-bottom-left-radius: 50%;">
						
						<slot></slot>
						
						<div class="text-center">
							<h1 class="">
								{{useDepartmentTargets ? departmentTargetUnitName : 'Totalt for valgt periode' }}
							</h1>
							
							<h3 
								v-if="stats.dateRange && stats.dateRange.fromTimestamp"
								class="opacity-75">
								
								<span class="">
									<span class="capitalize">{{ stats.dateRange.fromTimestamp  | moment('dddd') }}</span>
									{{ stats.dateRange.fromTimestamp  | moment('Do MMM YYYY') }}
								</span>
								<span class="mx-3 opacity-75">
									<i class="fas fa-chevron-right" />
								</span>
								<span class="">
									<span class="capitalize">{{ stats.dateRange.toTimestamp  | moment('dddd') }}</span>
									{{ stats.dateRange.toTimestamp  | moment('Do MMM YYYY') }}
								</span>
							</h3>
						</div>
						
						
						
						<div class="flex space-x-4 mx-auto max-w-sm pt-6 pb-12">
							<div class="flex-1 mt-4 text-left  flex items-center">
								
								<div class="">
									<div class="opacity-75 whitespace-no-wrap">Mine resultater</div>
									
									<div v-if="stats.user?.totals">
										<div v-if="stats.user.totals.products?.sum || stats.user.totals.tasks?.qty">
											<div 
												v-if="stats.user.totals.products?.sum"
												class="flex">
												
												<div class="mr-1">Salg:</div>
												<div class="whitespace-nowrap">kr {{currencyFormat(stats.user.totals.products.sum)}}</div>
											</div>
											<div 
												v-if="stats.user.totals.tasks?.qty"
												class="flex">
												
												<div class="mr-1">Oppgaver:</div>
												<div class="whitespace-nowrap">{{ stats.user.totals.tasks?.qty }}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							
							<div class="flex-none rounded-full w-32 h-32 flex overflow-hidden bg-beige-dark border-white">
								<img 
									v-if="picture"
									:src="picture"
									class="w-full h-full object-cover" 
								/>
								<i 
									v-else
									class="far fa-grin-wink w-full h-full items-center justify-center flex text-2xl bg-beige-dark" 
								/>
							</div>
							
							<div class="flex-1 mt-4 flex flex-col items-end text-right">
								<div class="flex-none space-x-4 flex items-center h-full ">
									<div 
										v-if="stats.user.totals.kudos.heart"
										class="rounded-full flex items-center justify-center space-x-1 px-2 h-8 bg-white">
										
										<i 
											class="fas w-6 text-center fa fa-heart" 
										/>
										<div>
											{{stats.user.totals.kudos.heart}}
										</div>
									</div>
									
									<div 
										v-if="stats.user.totals.kudos.thumbsUp"
										class="rounded-full flex items-center justify-center space-x-1 px-2 h-8 bg-white">
										
										<i 
											class="fas w-6 text-center fa fa-thumbs-up" 
										/>
										<div>
											{{stats.user.totals.kudos.thumbsUp}}
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</div>
			<div 
				v-else
				class="p-6 md:p-12 bg-beige">
				
				<div class="text-center mb-6">
					<h1>
						{{useDepartmentTargets ? departmentTargetUnitName : 'Totalt for valgt periode' }}
					</h1>
					
					<h3 v-if="stats.dateRange && stats.dateRange.fromTimestamp">
						<span class="">
							<span class="capitalize">{{ stats.dateRange.fromTimestamp  | moment('dddd') }}</span>
							{{ stats.dateRange.fromTimestamp  | moment('Do MMM YYYY') }}
						</span>
						<span class="mx-3 opacity-75">
							<i class="fas fa-chevron-right" />
						</span>
						<span class="">
							<span class="capitalize">{{ stats.dateRange.toTimestamp  | moment('dddd') }}</span>
							{{ stats.dateRange.toTimestamp  | moment('Do MMM YYYY') }}
						</span>
					</h3>
				</div>
				
				<div v-if="userStats">
					<div class="rounded-full w-32 h-32 flex overflow-hidden bg-beige-dark mb-6 mx-auto border-4 border-white">
						<img 
							v-if="picture"
							:src="picture"
							class="w-full h-full object-cover" 
						/>
						<i 
							v-else
							class="far fa-grin-wink w-full h-full items-center justify-center flex text-2xl bg-beige-dark" 
						/>
					</div>
					
					<div 
						v-if="stats.user?.totals"
						class="bg-white rounded-lg overflow-hidden mb-6 pt-8 pb-8 -mt-12">
						
						<div 
							v-if="stats.user.totals.products.sum || stats.user.totals.tasks?.qty"
							class="flex items-start justify-center text-left leading-none space-x-8">
							
							<div 
								v-if="stats.user.totals.products.sum"
								class="flex-grow text-center">
								
								<h3 class="m-0">Ditt produktsalg</h3>
								<h1 class="mt-2 text-2xl">kr {{currencyFormat(stats.user.totals.products.sum)}}</h1>
							</div>
							<div 
								v-if="stats.user.totals.tasks?.qty"
								class="flex-grow text-center">
								
								<h3 class="m-0">Oppgaver utført</h3>
								<h1 class="mt-2 text-2xl">{{ stats.user.totals.tasks.qty }}</h1>
							</div>
						</div>
						<div 
							v-else
							class="text-center px-6">
							
							<h2>Her var det tomt</h2>
							<p>
								Vi har ikke registrert noen solgte produkter eller oppgaver utført i perioden for deg ennå.
							</p>
						</div>
					</div>
				</div>
			</div>
			
			 -->
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			<div class="p-6 md:p-12 ">
				
				<div 
					v-if="
						(stats?.account?.leaderboard?.active || stats.leaderboardOverride) && 
						leaderboards && 
						(leaderboards.products && leaderboards.products.length || leaderboards.tasks && leaderboards.tasks.length)
					"
					class="md:flex py-6 md:space-x-6 space-y-6 md:space-y-0 mb-6 ">
					
					<LeaderboardTotal 
						v-if="leaderboards.products && leaderboards.products.length"
						:title="`Topp ${stats.leaderboardOverride ? stats.leaderboardOverride.positions : stats.account.leaderboard.positions} salg av produkter`"
						:settings="stats.leaderboardOverride || stats.account.leaderboard"
						type="products"
						:users="stats.users"
						class="flex-grow"
						:positions="leaderboards.products"
					/>
					
					<LeaderboardTotal 
						v-if="leaderboards.tasks && leaderboards.tasks.length"
						:title="`Topp ${stats.leaderboardOverride ? stats.leaderboardOverride.positions : stats.account.leaderboard.positions} utførte oppgaver`"
						:settings="stats.leaderboardOverride || stats.account.leaderboard"
						type="tasks"
						:users="stats.users"
						class="flex-grow"
						:positions="leaderboards.tasks"
					/>
				</div>
				
				<div class="bg-white rounded-lg overflow-hidden">
					<table class="text-sm md:text-base ">
						<thead>
							<tr>
								<th class="text-left">
									Avdeling
								</th>
								<th class="text-right w-1/4">
									Produkter
								</th>
								<th class="text-right w-1/4">
									Oppgaver
								</th>
							</tr>
						</thead>
						<tbody>
							<template v-for="department in stats.departments">
								<tr 
									:key="'department_'+department.departmentId"
									:class="mode == 'total' && departmentId == department.departmentId ? 'pulseCurrentDepartment' : ''">
									
									<td class="flex-grow ">
										{{department.name}}
									</td>
									<td class="text-right bg-beige-light">
										<span v-if="department.stats">kr {{ currencyFormat(department.stats.totals.products?.sum) }}</span>
									</td>
									<td class="text-right">
										<span v-if="department.stats">{{ currencyFormat(department.stats.totals.tasks?.qty) }}</span>
									</td>
								</tr>
							</template>
						</tbody>
						<tfoot>
							<tr class="text-gray-darkest border-t-4 border-beige">
								<td class="text-left">
									Totalt
								</td>
								<td class="text-right bg-beige-light">
									kr {{currencyFormat(total.products?.sum)}}
								</td>
								<td class="text-right">
									{{currencyFormat(total.tasks?.qty)}}
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
				
			</div>
				
		</div>
		
		
		
		
		
		
		
		
		
		
		
		

		<div v-if="mode == 'all' || mode == 'department'">
			
			<div 
				v-for="department in stats.departments.filter( d => {
					if( departmentId && departmentId == d.departmentId ){
						return true;
					}
					else if( !departmentId ){
						return true;
					}
					
					return false;
				})"
				:key="'department_department_stats_'+department.departmentId"
				class="overflow-hidden bg-beige md:rounded-lg "
				:class="mode == 'all' ? 'mt-24' : ''">
				
				<div class="">
					<div class="md:flex bg-beige-dark p-6 md:px-12 space-y-2">
						<header class="flex-1 flex items-center">
							<div 
								v-if="department.icon"
								class="flex-none w-32 h-32 flex items-center justify-center">
								
								<img 
									:src="`/icons/${department.icon}`"
									class="w-24 h-full opacity-75"
								/>
							</div>
							
							<div class="flex-grow ml-8 ">
								<h2 class="leading-none text-black">{{department.name}}</h2>
								
								<template v-if="useDepartmentTargets">
									<div 
										v-if="
											department.departmentTargets &&
											department.departmentTargets.products &&
											department.departmentTargets.tasks &&
											( (department.departmentTargets.products.metric == 'total' && department.departmentTargets.products.sum) || department.departmentTargets.products.metric == 'individually' ) ||
											( (department.departmentTargets.tasks.metric == 'total' && department.departmentTargets.tasks.qty) || department.departmentTargets.tasks.qty == 'individually' )
										">
										
										Mål for <span class="lowercase">{{departmentTargetUnitName}}</span>
									</div>
									<div v-else>
										Ingen periodemål for avdelingen
									</div>
								</template>
							</div>
						</header>
						
						<div 
							v-if="useDepartmentTargets"
							class="flex-1 flex items-center">
							
							<div 
								v-if="
									stats && 
									departmentCampaigns &&
									departmentCampaigns[ department.departmentId ]
								">
								
								<OverviewCampaign 
									:campaign="departmentCampaigns[ department.departmentId ]"
								/>
							</div>
						</div>
					</div>
					
					<div v-if="department.stats && department.stats.totals">
						<div 
							v-if="useDepartmentTargets"
							class="flex flex-col md:flex-row px-6 md:px-12">
							
							<div 
								v-if="department.departmentTargets.products.metric == 'total' && department.departmentTargets.products.sum"
								class="flex-1 flex space-x-8 mt-8 items-center">
								
								<div class="w-32 rounded-full relative">
									<svg viewBox="0 0 36 36">
										<path 
											d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" 
											fill="none" 
											class="text-beige-dark"
											stroke="currentColor" 
											stroke-width="2.98" 
											stroke-linecap="round"
										/>
										<path 
											d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" 
											fill="none" 
											:class="department.stats.totals.products.sum >= department.departmentTargets.products.sum ? 'text-green' : 'text-yellow-500'"
											stroke="currentColor" 
											stroke-width="3" 
											:stroke-dasharray="`${department.stats.totals.products.sum >= department.departmentTargets.products.sum ? 100 : Math.round( department.stats.totals.products.sum / department.departmentTargets.products.sum * 100 )}, 100`" 
											stroke-linecap="round"
										/>
									</svg>
									
									<div class="absolute inset-0 flex items-center justify-center text-2xl">
										<div class="font-bold items-start flex text-black">
											{{ Math.round( department.stats.totals.products.sum / department.departmentTargets.products.sum * 100 ) }}%
										</div>
									</div>
								</div>
								
								<div class="leading-none">
									<h3 class="m-0">Totalt produktsalg</h3>
									<h1 class="my-2 text-2xl">kr {{currencyFormat(department.stats.totals.products.sum)}}</h1>
									<h3 class="m-0">Mål satt til <span class="text-black">kr {{currencyFormat(department.departmentTargets.products.sum)}}</span></h3>
								</div>
							</div>
							<div 
								v-else-if="department.departmentTargets.products.metric == 'individually'"
								class="flex-1 flex space-x-8 mt-8 items-center">
								
								<div class="w-32 rounded-full relative">
									<svg viewBox="0 0 36 36">
										<path 
											d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" 
											fill="none" 
											class="text-beige-dark"
											stroke="currentColor" 
											stroke-width="2.98" 
											stroke-linecap="round"
										/>
										<path 
											d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" 
											fill="none" 
											:class="individuallyPercentageTotal_products(department).differencePercent >= 100 ? 'text-green' : 'text-yellow-500'"
											stroke="currentColor" 
											stroke-width="3" 
											:stroke-dasharray="`${individuallyPercentageTotal_products(department).differencePercent}, 100`" 
											stroke-linecap="round"
										/>
									</svg>
									
									<div class="absolute inset-0 flex items-center justify-center text-2xl">
										<div class="font-bold items-start flex text-black">
											{{ Math.round(individuallyPercentageTotal_products(department).differencePercent) }}%
										</div>
									</div>
									
									
								</div>
								
								<div class="leading-none">
									<h3 class="m-0">Periodemål er satt</h3>
									<h1 class="my-2 text-2xl">Individuelt</h1>
									<h3 class="m-0">for produkter</h3>
								</div>
								
							</div>
							
							<div 
								v-if="department.departmentTargets.tasks.metric == 'total' && department.departmentTargets.tasks.qty"
								class="flex-1 flex space-x-8 mt-8  items-center">
								
								<div class="w-32 rounded-full relative">
									<svg viewBox="0 0 36 36">
										<path 
											d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" 
											fill="none" 
											class="text-beige-dark"
											stroke="currentColor" 
											stroke-width="2.98" 
											stroke-linecap="round"
										/>
										<path 
											d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" 
											fill="none" 
											:class="department.stats.totals.tasks.qty >= department.departmentTargets.tasks.qty ? 'text-green' : 'text-yellow-500'"
											stroke="currentColor" 
											stroke-width="3" 
											:stroke-dasharray="`${department.stats.totals.tasks.qty >= department.departmentTargets.tasks.qty ? 100 : Math.round( department.stats.totals.tasks.qty / department.departmentTargets.tasks.qty * 100 )}, 100`" 
											stroke-linecap="round"
										/>
									</svg>
									
									<div class="absolute inset-0 flex items-center justify-center text-2xl">
										<div class="font-bold items-start flex text-black">
											{{ Math.round( department.stats.totals.tasks.qty / department.departmentTargets.tasks.qty * 100 ) }}%
										</div>
									</div>
								</div>
								
								<div class="leading-none">
									<h3 class="m-0">Totalt utført</h3>
									<h1 class="my-2 text-2xl">{{ department.stats.totals.tasks.qty }} oppgaver</h1>
									<h3 class="m-0">Mål satt til <span class="text-black">{{ department.departmentTargets.tasks.qty }}</span> oppgaver</h3>
								</div>
							</div>
							<div 
								v-else-if="department.departmentTargets.tasks.metric == 'individually'"
								class="flex-1 flex space-x-8 mt-8 items-center">
								
								<div class="w-32 rounded-full relative">
									<svg viewBox="0 0 36 36">
										<path 
											d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" 
											fill="none" 
											class="text-beige-dark"
											stroke="currentColor" 
											stroke-width="2.98" 
											stroke-linecap="round"
										/>
										<path 
											d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" 
											fill="none" 
											:class="individuallyPercentageTotal_tasks(department).differencePercent >= 100 ? 'text-green' : 'text-yellow-500'"
											stroke="currentColor" 
											stroke-width="3" 
											:stroke-dasharray="`${individuallyPercentageTotal_tasks(department).differencePercent}, 100`" 
											stroke-linecap="round"
										/>
									</svg>
									
									<div class="absolute inset-0 flex items-center justify-center text-2xl">
										<div class="font-bold items-start flex text-black">
											{{ Math.round(individuallyPercentageTotal_tasks(department).differencePercent) }}%
										</div>
									</div>
								</div>
								
								
								<div class="leading-none">
									<h3 class="m-0">Periodemål er satt</h3>
									<h1 class="my-2 text-2xl">Individuelt</h1>
									<h3 class="m-0">for oppgaver</h3>
								</div>
							</div>
							
						</div>
					</div>
					<div 
						v-else
						class="text-center">
						
						Fant ingen statistikk for avdelingen
					</div>
					
					<div 
						v-if="
							((department.leaderboard && department.leaderboard.active) || stats.leaderboardOverride) && 
							department.stats &&
							department.stats.leaderboard
						"
						class="md:flex py-6 px-6 md:px-12 md:space-x-6 space-y-6 md:space-y-0 ">
						
						<Leaderboard 
							:title="`Topp ${stats.leaderboardOverride ? stats.leaderboardOverride.positions : department.leaderboard.positions} salg av produkter`"
							:settings="stats.leaderboardOverride || department.leaderboard"
							type="products"
							:users="stats.users"
							class="flex-1"
							:leaderboard="department.stats.leaderboard"
						/>
						
						<Leaderboard 
							:title="`Topp ${stats.leaderboardOverride ? stats.leaderboardOverride.positions : department.leaderboard.positions} utførte oppgaver`"
							:settings="stats.leaderboardOverride || department.leaderboard"
							type="tasks"
							:users="stats.users"
							class="flex-1"
							:leaderboard="department.stats.leaderboard"
						/>
					</div>
				</div>
				
				<div class="p-6 md:p-12 space-y-6 md:space-y-12">
					<div 
						v-if="department && department.products && department.products.length && department.stats && department.stats.products && department.stats.totals && department.stats.totals.products"
						class="bg-white rounded-lg overflow-hidden">
						
						<table class="text-sm md:text-base">
							<thead>
								<tr class="bg-yellow-300">
									<th class="text-left">
										Produkter
									</th>
									<th class="text-right w-1/4">
										Enheter solgt
									</th>
									<th 
										v-if="useDepartmentTargets"
										class="text-right w-1/4">
										
										Periodemål
									</th>
									<th class="text-right w-1/4">
										Sum
									</th>
								</tr>
							</thead>
							<tbody>
								<tr 
									v-for="product in department.products"
									:key="'department_stats_product_'+product.productId"
									class="">
									
									<td 
										class="flex-grow text-gray-darkest"
										:title="product.productId">
										
										{{product.name}}
									</td>
									<td class="flex-grow bg-beige-light text-right">
										<div v-if="department.stats && department.stats.products && department.stats.products[ product.productId ]">
											{{department.stats.products[ product.productId ].qty}}
										</div>
										<div v-else>
											0
										</div>
									</td>
									<td 
										v-if="useDepartmentTargets"
										class="flex-grow text-right">
										
										<div v-if="department.departmentTargets.products.metric == 'individually' && product.target && product.target.sum">
											kr {{ currencyFormat(product.target.sum) }}
										</div>
										<div v-else>
											-
										</div>
									</td>
									<td class="w-32 bg-beige-light text-right">
										<div v-if="department.stats && department.stats.products && department.stats.products[ product.productId ]">
											kr {{ currencyFormat(department.stats.products[ product.productId ].sum) }}
										</div>
										<div v-else>
											kr 0
										</div>
									</td>
								</tr>
							</tbody>
							<tfoot>
								<tr class="text-gray-darkest border-t-4 border-beige">
									<td class="text-left">
										Totalt
									</td>
									<td class="text-right">
										<div v-if="department.stats && department.stats.totals && department.stats.totals.products">
											{{ department.stats.totals.products.qty }}
										</div>
										<div v-else>
											0
										</div>
									</td>
									<td 
										v-if="useDepartmentTargets"
										class="text-right">
										
										<div v-if="department.departmentTargets.products.metric == 'total' && department.departmentTargets.products.sum">
											kr {{ currencyFormat(department.departmentTargets.products.sum) }}
										</div>
										<div v-else>
											-
										</div>
									</td>
									<td class="text-right">
										<div v-if="department.stats && department.stats.totals && department.stats.totals.products">
											kr {{currencyFormat(department.stats.totals.products.sum)}}
										</div>
										<div v-else>
											kr 0
										</div>
									</td>
								</tr>
							</tfoot>
						</table>
					</div>
					<div 
						v-if="department.tasks && department.tasks.length && department.stats && department.stats.tasks && department.stats.totals && department.stats.totals.tasks"
						class="bg-white rounded-lg overflow-hidden">
						
						<table class="text-sm md:text-base">
							<thead>
								<tr>
									<th class="text-left">
										Oppgaver
									</th>
									<th 
										v-if="useDepartmentTargets"
										class="text-right w-1/4">
										
										Periodemål
									</th>
									<th class="text-right w-1/4">
										Oppgaver utført
									</th>
								</tr>
							</thead>
							<tbody>
								<tr 
									v-for="task in department.tasks"
									:key="'department_stats_task_'+task.taskId"
									class="">
									
									<td 
										class="flex-grow text-gray-darkest"
										:title="task.taskId">
										
										{{task.name}}
									</td>
									<td 
										v-if="useDepartmentTargets"
										class="flex-grow bg-beige-light text-right">
										
										<div v-if="department.departmentTargets.tasks.metric == 'individually'">
											{{task.target.qty}}
										</div>
										<div v-else>
											-
										</div>
									</td>
									<td class="flex-grow  text-right">
										<div v-if="department.stats && department.stats.tasks && department.stats.tasks[ task.taskId ]">
											{{department.stats.tasks[ task.taskId ].qty}}
										</div>
										<div v-else>
											0
										</div>
									</td>
								</tr>
							</tbody>
							<tfoot>
								<tr class="text-gray-darkest border-t-4 border-beige">
									<td class="text-left">
										Totalt
									</td>
									<td 
										v-if="useDepartmentTargets"
										class="text-right">
										
										<div v-if="department.departmentTargets.tasks.metric == 'total'">
											{{ department.departmentTargets.tasks?.qty }}
										</div>
										<div v-else>
											-
										</div>
									</td>
									<td class="text-right">
										<div v-if="department.stats && department.stats.totals && department.stats.totals.tasks">
											{{ department.stats.totals.tasks?.qty }}
										</div>
										<div v-else>
											-
										</div>
									</td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div 
		v-else
		class="flex items-center justify-center h-full">
		
		Fant ingen statistikk i noen avdelinger for perioden
	</div>
</template>

<script>
	export default {
		components: {
            'Leaderboard': () => import('@/components/stats/Leaderboard.vue'),
            'LeaderboardTotal': () => import('@/components/stats/LeaderboardTotal.vue'),
            'OverviewCampaign': () => import('@/components/stats/OverviewCampaign.vue'),
        },
        
		props: {
			loading: {
				type: String,
				// required: true,
			},
			stats: {
				type: Object,
				// required: true,
			},
			mode: {
				type: String,
				required: false,
				default: 'all', // all, total, 
			},
			departmentId: {
				type: String,
				required: false,
			},
			userStats: {
				type: Boolean,
				required: false,
				default: false,
			},
			useDepartmentTargets: {
				type: Boolean,
				default: true,
			},
			userStatsMode: {
				type: String,
				required: false,
				default: null, // 'app'  || null
			},
		},
		
		computed:{
			departmentTargetUnitName(){
				let name;
				
				switch (this.stats.account.departmentTargetUnit) {
					case 'week':
						name = 'Denne uken';
						break;
					case 'month':
						name = 'Denne måneden';
						break;
					case 'quarterly':
						name = 'Dette kvartalet';
						break;
					case 'year':
						name = 'Dette året';
						break;
				}
				
				return name;
			},
			
			total(){
				if ( this.stats ) {
					let responseTotal = {
						products: {
							qty: 0,
							sum: 0,
						},
						tasks: {
							qty: 0,
						},
						departments: {},
					};
					
					
					// Find percentages if departmentTargets is defined...
					this.stats.departments.forEach( department => {
						// console.log(department.name);
						// console.log(department.stats);
						
						if (
							department.departmentTargets &&
							department.stats &&
							department.stats.totals ) {
								
							let percentComplete = {
								products: null,
								tasks: null,
							};
							
							// products
							if (department.departmentTargets.products.metric == 'individually') {
								const response = this.individuallyPercentageTotal_products( department );
								percentComplete.products = response.differencePercent;
							}
							else if (department.departmentTargets.products.metric == 'total') {
								const response = (department.stats.totals.products.sum / department.departmentTargets.products.sum) * 100;
								percentComplete.products = response;
							}
							
							// tasks
							if (department.departmentTargets.tasks.metric == 'individually') {
								const response = this.individuallyPercentageTotal_tasks( department );
								percentComplete.tasks = response.differencePercent;
							}
							else if (department.departmentTargets.tasks.metric == 'total') {
								const response = (department.stats.totals.tasks?.qty / department.departmentTargets.tasks?.qty) * 100;
								percentComplete.tasks = response;
							}
							
							responseTotal.departments[department.departmentId] = percentComplete;
						}
						
						
						// get total of all products and tasks - even if if doesnt have departmentTargets defined
						if (
							department.stats &&
							department.stats.totals ) {
							
							responseTotal.products.qty += department.stats.totals.products?.qty;
							responseTotal.products.sum += department.stats.totals.products?.sum;
							
							responseTotal.tasks.qty += department.stats.totals.tasks?.qty;
						}
						
					});
					
					return responseTotal;
				}
				return null;
			},
			
			picture(){
				try {
					if (this.signedInUser.signInUserSession.idToken.payload.picture){
						return this.fileKeyToUrl( this.signedInUser.signInUserSession.idToken.payload.picture );
					}
					return null;
				} 
				catch (error) {
					console.log('no picture found. error:');
					console.error(error);
					return null;
				}
			},
			
			leaderboards(){
				if ( !this.stats || !this.stats.leaderboard){
					return null;
				}
				
				let products = [];
				let tasks = [];

				for ( const [username, value] of Object.entries( this.stats.leaderboard ) ) {
					if ( value.totals.products.sum ) {
						products.push( {
							user: this.stats.users.find( u => u.username == username),
							sum: value.totals.products.sum,
							qty: value.totals.products.qty,
						});
					}
					if ( value.totals.tasks.qty ) {
						tasks.push( {
							user: this.stats.users.find( u => u.username == username),
							qty: value.totals.tasks.qty,	
						});
					}
				}
				
				products = products
					.filter( item => item.qty || item.sum )
					.sort((a, b) => b.sum > a.sum ? 1 : -1);
				tasks = tasks
					.filter( item => item.qty )
					.sort((a, b) => b.qty > a.qty ? 1 : -1);
				
				return {
					products,
					tasks,
				};
			},
			
			departmentCampaigns() {
				let response = {};
				
				if ( 
					this.stats.campaigns && 
					this.stats.campaigns.length) {
					
					this.stats.campaigns
						.filter( c => {
							const date = new Date();
							const start = new Date( c.startAt );
							const end = new Date( c.endAt );
							
							if (date > start && date < end) {
								return true
							}
						})
						.map( c => {
							response[ c.departmentId ] = c;
						})
				}
				
				return response;
			},
		},
		
		data(){
			return {
				// loading: null,
				// overviewMode: 'current',
			}
		},
		
		methods: {
			
			individuallyPercentage_products( department ){
				let productsWithTarget = JSON.parse( JSON.stringify(department.products.filter(p => p.target.sum)) );
				
				return productsWithTarget
					.map( product => {
						let percent;
						try {
							if (
								department.stats.totals.products && 
								department.stats.totals.products.sum && 
								product.target && 
								product.target.sum ){
									
								if ( 
									department.stats.products[ product.productId ] && 
									department.stats.products[ product.productId ].sum ){
									
									percent = department.stats.products[ product.productId ].sum >= product.target.sum ? 100 : Math.round( department.stats.products[ product.productId ].sum / product.target.sum * 100 );
								}
								else {
									percent = 0;
								}
							}
						} 
						catch (e) {
							console.log('e', e);
						} 
						
						return {
							...product,
							percent,
						}
					})
					.filter( product => product.percent !== null);
			},
			
			individuallyPercentageTotal_products( department ){
				const individuallyPercentage = this.individuallyPercentage_products( department );
				let totalPercent = individuallyPercentage.length * 100;
				let completedPercent = 0;
				
				individuallyPercentage.forEach( product => {
					completedPercent += product.percent;
				});
				
				let differencePercent = (completedPercent / totalPercent) * 100;
				
				return {
					totalPercent: totalPercent || 0,
					completedPercent: completedPercent || 0,
					differencePercent: differencePercent || 0,
				};
			},
			
			individuallyPercentage_tasks( department ){
				let tasksWithTarget = JSON.parse( JSON.stringify(department.tasks.filter(t => t.target.qty)) );
				// console.log('tasksWithTarget', tasksWithTarget);
				
				return tasksWithTarget
					.map( task => {
						let percent;
						try {
							if (
								department.stats.totals.tasks && 
								department.stats.totals.tasks.qty && 
								task.target && 
								task.target.qty ){
								
								if ( 
									department.stats.tasks[ task.taskId ] && 
									department.stats.tasks[ task.taskId ].qty ) {
									
									percent = department.stats.tasks[ task.taskId ].qty >= task.target.qty ? 100 : Math.round( department.stats.tasks[ task.taskId ].qty / task.target.qty * 100 );
								}
								else {
									percent = 0;
								}
							}
						} 
						catch (e) {
							console.log('e', e);
						} 
				
						return {
							...task,
							percent,
						}
					})
					.filter( task => task.percent !== null);
			},
			
			individuallyPercentageTotal_tasks( department ){
				const individuallyPercentage = this.individuallyPercentage_tasks( department );
				let totalPercent = individuallyPercentage.length * 100;
				let completedPercent = 0;
				
				individuallyPercentage.forEach( task => {
					completedPercent += task.percent;
				});
				
				let differencePercent = (completedPercent / totalPercent) * 100;
				
				return {
					totalPercent: totalPercent || 0,
					completedPercent: completedPercent || 0,
					differencePercent: differencePercent || 0,
				};
			},
		},
	}
</script>

<style lang="scss">
	.pulseCurrentDepartment {
		td {
			animation: pulseAnimation 1s linear infinite alternate;
		}
	}
	
	@keyframes pulseAnimation {
		from {
			@apply text-black;
		}

		to {
			@apply bg-beige-dark;
		}
	}
</style>